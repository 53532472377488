import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access - Interarchy",
  "path": "/Motion_Detection/SD_Card_Access/Interachy/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the Camera Recordings on the internal SD Card",
  "image": "./MD_SearchThumb_SDCardAccess_Interachy.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess_Interachy.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='SD Card Access - Interarchy' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the Camera Recordings on the internal SD Card' image='/images/Search/MD_SearchThumb_SDCardAccess_Interachy.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess_Interachy.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/Interachy/' locationFR='/fr/Motion_Detection/SD_Card_Access/Interachy/' crumbLabel="SD Card" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "interarchy---ftp-access-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interarchy---ftp-access-to-the-internal-sd-card",
        "aria-label": "interarchy   ftp access to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interarchy - FTP Access to the internal SD Card`}</h2>
    <p>{`Interarchy for macOSX is a paid FTP client that you can use to access your cameras SD card. We recommend you to download the latest version from `}<Link to="https://interarchy.com" target="_blank" rel="noopener noreferrer" mdxType="Link">{`here`}</Link>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "820px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0883b68c238fb46492d17f9980697c6a/9f82e/Interarchy-SD_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQVQoz43OW4+aQACG4fn//6FNusmmN012a2K7ClpPK4dBGGaAYUYYUIFdDitKs+LeNZq2SU2TNXny5b38wONsoFiqNB7OdAUiU10YOjKZCAnnBsbIc2i45JFgUch+7wmPQjfg4Ob2S6fz/dPN5w8fb+/uOt3ut/v7r71eX5bl4WAoD4aSJEt9SZLk/nlPfY6Hhx7oDvSurElTPDECxaKQ+BqmCnJPrDP0P5Y7t1zghytFtzBdJvkL5rGGfR0znZxo+JL+NwgzHA4WbjDTLOwLkZaWF0LCMBOYCcKEw6M/LhvRQMM+MN2lamB1QQgT0OFMrI7HtirL5zzfJFm9b7a7/b+aetdkeanaFJgOt70gyqooLaDDvSBuD4e6rsNVRljkhWvMTqeyl59p1aRVsyn2SdlEaa4iD2jYn6rmcpXFaakTRoP47dgeXl/b9nBs2wtvx5Zne7TarZ8qxXKASoLR3LBpEGcVJExBLqZL+z0Lh81MB3gi9aOEx1mUlpDwkW6Pr4KnBgE8zuKsEkkRJQXEbKTbU4O8awLxBGLAolRs8mCTi6TQbPpDNcc6Gl0HhEmxft7GT9t1XusOH2poBPGVAEQOItQiFBE6UaA0fpQn82tI4/kvO3yz6bxdH3kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0883b68c238fb46492d17f9980697c6a/e4706/Interarchy-SD_01.avif 230w", "/en/static/0883b68c238fb46492d17f9980697c6a/d1af7/Interarchy-SD_01.avif 460w", "/en/static/0883b68c238fb46492d17f9980697c6a/bbc6f/Interarchy-SD_01.avif 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0883b68c238fb46492d17f9980697c6a/a0b58/Interarchy-SD_01.webp 230w", "/en/static/0883b68c238fb46492d17f9980697c6a/bc10c/Interarchy-SD_01.webp 460w", "/en/static/0883b68c238fb46492d17f9980697c6a/b1f58/Interarchy-SD_01.webp 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0883b68c238fb46492d17f9980697c6a/81c8e/Interarchy-SD_01.png 230w", "/en/static/0883b68c238fb46492d17f9980697c6a/08a84/Interarchy-SD_01.png 460w", "/en/static/0883b68c238fb46492d17f9980697c6a/9f82e/Interarchy-SD_01.png 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0883b68c238fb46492d17f9980697c6a/9f82e/Interarchy-SD_01.png",
              "alt": "Interarchy - FTP Access to the internal SD Card",
              "title": "Interarchy - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Choose the `}<strong parentName="p">{`HTTP protocol`}</strong>{` and type in your cameras local IP or DDNS address. Add the directory `}<code>{`/web/sd/`}</code>{` to the address.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "821px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/02cd5/Interarchy-SD_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFUlEQVQoz43T226bMBgHcN7/pnuAvsCUY6vtZneT2iSlBYITDgEDJhQI2OYYjknDBFmr3lTbp598kGX/LUtmHPcVY6yqqm3bCCHLsnzfhxDqur7baaq6067V97qu647jZFmW5XmSpMzjciVL8mq1enh4WCwWLMve3t4aEHIcJ223CFmmYSDLgjo0DOMQYJZlgQhMw9A0jVk8PS+XS2EtqKoqCGuWZW++3extW5ZlVTf9kKR5kWRFeiyTJE6dLdhsCY2b9lxWDeO8ukAElmVlaQo2UhRFXdeFYWiapgL3yCU0qz2S+iTT3NRCr7Ks0aRo37qqvTAaNEVRRAhFUcQLIgAbWVYkSeJ5gXvhAACKovA8z3Pcr0fxx2/x6ZmP06I5dVXTbzYAEGVZspGt6eb8/uf30XQ0mY+nd5PZ/WgyH41n4+ndeHo3m82n0xn7whf1uW67uu36ZAhhHMeEkDQvj9UpL5u8bPNiULYf42xwrM5Ve6kHjKIZvCD4/oFSmhVVfequp36lai+Drmo7RoZovQY2siml+bHf/L78pSF2uLbnH8IwxBgTQvJj3fwr+TMmCIM4jiiNCMF58V/JH5hDENKhCMZFWZ8uXfv27jx4nzbnrn+RT5ggxHF0LRrgKMDxYWgxTUiU9mhyRaP0WDRF2X5g7L3je57reb7v7aC5VbWNpEjqzu4/iWmaxt62/9rvvU/luu4fu07347KB9o4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/e4706/Interarchy-SD_02.avif 230w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/d1af7/Interarchy-SD_02.avif 460w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/d02b5/Interarchy-SD_02.avif 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/a0b58/Interarchy-SD_02.webp 230w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/bc10c/Interarchy-SD_02.webp 460w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/d77d0/Interarchy-SD_02.webp 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/81c8e/Interarchy-SD_02.png 230w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/08a84/Interarchy-SD_02.png 460w", "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/02cd5/Interarchy-SD_02.png 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05d3f1ab4dcf87e36d91b4d52e4e7c42/02cd5/Interarchy-SD_02.png",
              "alt": "Interarchy - FTP Access to the internal SD Card",
              "title": "Interarchy - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Type in your camera login to connect to the SD storage.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "819px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/97655/Interarchy-SD_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8UlEQVQoz22QWY+bQBCE+f+Pide7Sp6T/JnE9tp4OOfmMjMD2MAw4IMIe09rS59KXQ+tbpWFOaNxtFg/u4GPI86SmCcJ5gwyGhDiI8TTRFZlXhbiM5mS1tYGMIT2ZgO2WwSh73lb23YdxwHABcABwHM9BCGCEIZB4Pu3GV2TBRxv8W9BKS2K8lA3daMPjdZdr7u+vbru+kabVptDo6t93Whzi602VhIny8UShqGUsmnNcB7N8Wv649ifrv6KhQhbrVacc6VU3RpzHLv+3A2Xr+k/RQsTsl6vHQAwxnVr+rtrp/vjZnjHIoQihMqyFEI0uq+7U0IIJQHnMWMcQkQp4zziPGI8qg76477lhWi5ehZCSCl1N8SpWG5cByKeSVHVcV5kap+XtaiaLNtBzK7LlxuWi+i/qW2mlGrNEdPo78YJSAJ8FBCOo5REGY13LMmDEALHn0oZXrB2QqZpmue5lLLtjnEmf/3+M58/PUw8fvs+m83mDw+Ps9n86cdP4IXDeez6y8vbhVLFVVKKpjVNd6LRLsQckXiCxrcB4pjydF+bt5+nt6VUUkqlJm86c7yM53G8jJPfcRrvy7eKd6lcljtZZaLIRClUJdT+SnVDFvtG9203vGGlr8qy1A2R7fo2cGzXo5QSPIm9inOeflCSJP8BlWz+6nStLpgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/e4706/Interarchy-SD_03.avif 230w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/d1af7/Interarchy-SD_03.avif 460w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/db5bb/Interarchy-SD_03.avif 819w"],
              "sizes": "(max-width: 819px) 100vw, 819px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/a0b58/Interarchy-SD_03.webp 230w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/bc10c/Interarchy-SD_03.webp 460w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/6d63c/Interarchy-SD_03.webp 819w"],
              "sizes": "(max-width: 819px) 100vw, 819px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/81c8e/Interarchy-SD_03.png 230w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/08a84/Interarchy-SD_03.png 460w", "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/97655/Interarchy-SD_03.png 819w"],
              "sizes": "(max-width: 819px) 100vw, 819px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6dec2aea1bea8a6ad41bd0830f4b682d/97655/Interarchy-SD_03.png",
              "alt": "Interarchy - FTP Access to the internal SD Card",
              "title": "Interarchy - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`Wait for the program to list the directories.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      